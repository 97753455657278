import React from "react";

// Chakra imports
import { Button, Flex, Link, Text, Icon, useColorModeValue, Image } from "@chakra-ui/react";
import { mdiAccount } from '@mdi/js';
import banner from "assets/img/nfts/NftBanner1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTwitter } from "react-icons/fa";

import XLogo from "assets/img/logoxwhite.png";
import DiscordLogo from "assets/img/discordLogoWhite.webp";

export default function Banner() {
  // Chakra Color Mode
  const iconColor = useColorModeValue("brand.500", "white");


  return (
    <Flex
      direction='column'
      bgImage={banner}
      bgSize='cover'
      py={{ base: "30px", md: "56px" }}
      px={{ base: "30px", md: "64px" }}
      borderRadius='30px'>
      <Text
        fontSize={{ base: "24px", md: "34px" }}
        color='white'
        mb='14px'
        maxW={{
          base: "100%",
          md: "100%",
          lg: "100%",
          xl: "100%",
          "2xl": "50%",
          "3xl": "42%",
        }}
        fontWeight='700'
        lineHeight={{ base: "32px", md: "42px" }}>
        Discover, learn, and farm bests Airdrops
      </Text>
      <Text
        fontSize='md'
        color='#E3DAFF'
        maxW={{
          base: "100%",
          md: "100%",
          lg: "100%",
          xl: "100%",
          "2xl": "46%",
          "3xl": "34%",
        }}
        fontWeight='500'
        mb='40px'
        lineHeight='28px'>
        Enter in this creative world. Discover now the latest Airdrop strategies or start
        creating your own!
      </Text>
      <Flex align='center'>
        <Button
          bg='white'
          color='black'
          _hover={{ bg: "whiteAlpha.900" }}
          _active={{ bg: "white" }}
          _focus={{ bg: "white" }}
          fontWeight='500'
          fontSize='14px'
          py='20px'
          px='27'
          me='38px'>
          Discover now
        </Button>
        {/* <Link>
          <Text color='white' fontSize='sm' fontWeight='500'>
            Watch video
          </Text>
        </Link> */}
        <Link _hover={{opacity: "0.6"}} href={"https://x.com/onlydrops_xyz"}>
          <Image src={XLogo} alt='logo' w={"26px"} ml={"0px"} color={"white"}/>
        </Link >
        <Link _hover={{opacity: "0.6"}} ml={"35px"} href={"https://x.com/padaw3n_"}>
          <Image src={DiscordLogo} alt='logo' w={"35px"} color={"white"}/>
        </Link >
      </Flex>
    </Flex>
  );
}
