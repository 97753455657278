import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdHome,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import StrategiesView from 'views/strategie/profile';
// Auth Imports
const routes = [
  {
    name: 'Dashboard',
    layout: '/dashboard',
    path: '/:walletAddress',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Airdrop checker',
    layout: '/strategies',
    path: '/allStrategies',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <StrategiesView />,
    secondary: true,
  },
];

export default routes;
