import React from "react";

// Chakra imports
import { Flex, Image, Badge, Link, useColorModeValue } from "@chakra-ui/react";
import Logo from "assets/img/newLogo.svg";
// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  return (
    <Flex align='center' direction='column' position='relative'>
      <Badge
        position='absolute'
        top='0'
        right='48'
        fontSize={"11px"}
        colorScheme='purple'
      >
        alpha
      </Badge>
      <Link href="/" mt={"0px"} mb={"5px"}>
        <Image src={Logo} alt='logo'  w={"230px"} mr={"15px"} />
      </Link>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
