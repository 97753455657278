import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Portal,
  Box,
  Flex,
  Link,
  Image,
  Icon,
  SimpleGrid,
  useColorModeValue,
  useDisclosure,
  Avatar,
  Text,
  Spacer,
  AlertIcon,
  Alert,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Sidebar from "components/sidebar/Sidebar";
import Navbar from "components/navbar/NavbarAdmin";
import Footer from "components/footer/FooterAdmin";
import { SidebarContext } from "contexts/SidebarContext";
import routes from "routes";
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdAttachMoney,
  MdBarChart,
  MdEmojiEvents,
  MdEventRepeat,
  MdEvStation,
  MdGroup,
  MdOutlinePointOfSale,
  MdOutlineShieldMoon,
  MdSwapHoriz,
} from "react-icons/md";
import ComplexTable from "views/admin/default/components/ComplexTable";

import TotalSpent from "views/admin/default/components/TotalSpent";
import {
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataComplex2 from "views/admin/default/variables/tableDataComplex2.json";
import { PacmanLoader } from "react-spinners";
import { FaEthereum } from "react-icons/fa";

import AirdropSocialNetwork from "./components/airdropSocial.js";
import BcStatusAlert from "../../../components/alert/BcStatusAlert.js";

export default function Overview() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const { walletAddress, protocol } = useParams();
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { onOpen } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (!walletAddress) {
        return;
      }
      try {
        const response = await fetch(`https://drop-tracker-sage.vercel.app/api/v1/${walletAddress}/${protocol}`, {
          cache: 'no-store' // Ignore le cache
        })
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setData(data); // Assuming the API returns an array of chains
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const keyObject = tableDataComplex2.find(item => item[protocol]);
  // Extraire le tableau associé à cette clé
  const keyArray = keyObject ? keyObject[protocol] : [];
  return (
    <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
      <Sidebar routes={routes} display="none" />
      <Box
        float="right"
        minHeight="100vh"
        height="100%"
        overflow="auto"
        position="relative"
        maxHeight="100%"
        w={{ base: "100%", xl: "calc(100% - 290px)" }}
        maxWidth={{ base: "100%", xl: "calc(100% - 290px)" }}
        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        transitionDuration=".2s, .2s, .35s"
        transitionProperty="top, bottom, width"
        transitionTimingFunction="linear, linear, ease"
      >
        <Portal>
          <Box>
            <Navbar
              onOpen={onOpen}
              brandText={`Overview ` + protocol}
              secondary={false}
              message=""
              fixed={false}
            />
          </Box>
        </Portal>
        {loading ? (<Flex
          align="center"
          justify="center"
          h="100vh" // Utiliser 100% de la hauteur de la fenêtre
          w="100%" // Utiliser 100% de la largeur de la fenêtre
          bg={boxBg} // Utiliser la couleur de fond pour un meilleur contraste
        >
          <PacmanLoader number="2" />
        </Flex>) : (
          <>
            <Box pt={{ sm: "180px", md: "120px", xl: "120px" }} p={"30px"}>
              {data.status === "0" ? (<BcStatusAlert data={data}/>) : ""}

              <AirdropSocialNetwork data={data} />
              {/* <Card py='15px' mb='20px'>
                <Flex
                  my='auto'
                  h='100%'
                  align={{ base: "center", xl: "start" }}
                  justify={{ base: "start", xl: "start" }}>
                  <Box p='4'>
                    <Flex>
                      <Avatar size="sm" src={data.logo} />
                      <Text fontSize="xl" fontWeight="bold" ml={'10px'} style={{ textTransform: 'capitalize' }}>
                        {data.name}
                      </Text>
                    </Flex>
                  </Box>
                  <Spacer />
                  <Box p='4'>
                    <Flex>
                      <Link _hover={{ opacity: "0.6" }} href={"https://x.com/padaw3n_"}>
                        <Image src={"https://upload.wikimedia.org/wikipedia/commons/c/ce/X_logo_2023.svg"} alt='logo' w={"23px"} color={"white"} />
                      </Link >
                      <Link _hover={{ opacity: "0.6" }} href={"https://x.com/padaw3n_"} ml={'20px'}>
                        <Image src={"https://logo-marque.com/wp-content/uploads/2020/12/Discord-Logo.png"} alt='logo' w={"35px"} color={"white"} />
                      </Link >
                    </Flex>
                  </Box>
                </Flex>
              </Card> */}

              <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                {data.profilData ? (
                  <TotalSpent data={data.profilData.pts.length > 0 ? data.profilData.pts : [{ "nb": 0, "updatedAt": { "$date": "2024-08-09T21:26:27.591Z" } }]} airdropname={data.name} data2={data.profilData.pts2 && data.profilData.pts2.length > 0 ? data.profilData.pts2 : ""} />) : ""}
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='0px'>
                  <MiniStatistics
                    startContent={
                      <IconBox
                        w='56px'
                        h='56px'
                        bg={boxBg}
                        icon={
                          <Icon w='32px' h='32px' as={MdEmojiEvents} color={brandColor} />
                        }
                      />
                    }
                    name='Rank'
                    value={data.profilData.rank.length > 0 ? data.profilData.rank[0].nb : "N/A"}
                    growth='+0.00%'
                  />
                  <MiniStatistics startContent={
                    <IconBox
                      w='56px'
                      h='56px'
                      bg={boxBg}
                      icon={
                        <Icon w='32px' h='32px' as={MdGroup} color={brandColor} />
                      }
                    />
                  } growth='+0.00%' name='Nb Referral' value={data.profilData.nbReferral ? data.profilData.nbReferral : "0"} />
                  <MiniStatistics startContent={
                    <IconBox
                      w='56px'
                      h='56px'
                      bg={boxBg}
                      icon={
                        <Icon w='32px' h='32px' as={MdOutlinePointOfSale} color={brandColor} />
                      }
                    />
                  } growth='+0.00%' name='Referral XP earned' value={data.profilData.nbReferralEarnings[0] ? data.profilData.nbReferralEarnings[0].nb : "N/A"} />
                  <MiniStatistics startContent={
                    <IconBox
                      w='56px'
                      h='56px'
                      bg={boxBg}
                      icon={
                        <Icon w='32px' h='32px' as={MdOutlineShieldMoon} color={brandColor} />
                      }
                    />
                  } growth='+0.00%' name='Bagdes' value={data.profilData.badges ? data.profilData.badges : "N/A"} />
                </SimpleGrid>
              </SimpleGrid>
              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 4, "2xl": 6 }}
                gap='20px'
                mb='20px'>
                <MiniStatistics
                  startContent={
                    <IconBox
                      w='56px'
                      h='56px'
                      bg={boxBg}
                      icon={
                        <Icon w='32px' h='32px' as={MdSwapHoriz} color={brandColor} />
                      }
                    />
                  }
                  name='Interactions'
                  value={data.nbTransactions}
                  growth='+0.00%'
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w='56px'
                      h='56px'
                      bg={boxBg}
                      icon={
                        <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
                      }
                    />
                  }
                  name='Unique Contract Interactions'
                  value={data.nbContracts ? data.nbContracts : "N/A"}
                />
                <MiniStatistics startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={
                      <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                    }
                  />
                }
                  growth='+0.00%'
                  name='Interactions volume'
                  value={Number(data.totalVolumeEth).toFixed(4)}
                  icon={
                    <Icon w='18px' h='18px' as={FaEthereum} color={brandColor} />
                  }
                />

                <MiniStatistics startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={
                      <Icon w='32px' h='32px' as={MdEvStation} color={brandColor} />
                    }
                  />
                }
                  growth='+0.00%' name='Gas cost' value={`$${data.totalGasFeesUsd}`} />
                <MiniStatistics startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={
                      <Icon w='32px' h='32px' as={MdEvStation} color={brandColor} />
                    }
                  />
                }
                  growth='+0.00%' name='Average Gas cost' value={`$${(data.totalGasFeesUsd / data.nbTransactions).toFixed(2)}`} />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w='56px'
                      h='56px'
                      bg={boxBg}
                      icon={
                        <Icon w='32px' h='32px' as={MdEventRepeat} color={brandColor} />
                      }
                    />
                  }
                  name='Age since first interactions'
                  value={data.daysSinceFirstTx}
                />
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
                <ComplexTable
                  columnsData={columnsDataComplex}
                  tableData={keyArray}
                />
              </SimpleGrid>
            </Box>
          </>)}

        <Footer />
      </Box>
    </SidebarContext.Provider>
  );
}