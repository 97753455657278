import { Alert, AlertIcon } from "@chakra-ui/react";

const apiName = {
    'linea': 'Lineascan',
    'base': 'Basescan',
    'optimism': 'Optimismscan',
    'arbitrum': 'Arbiscan',
    'bsc': 'Bscscan',
    'eth': 'Etherscan',
    'polygon': 'Polygonscan',
    'gnosis': 'Gnosisscan',
}

export default function BcStatusAlert(props) {
    const { data } = props;
    let value = '';
    // Vérification si la chaîne correspond à une clé dans l'objet apiName
    if (apiName.hasOwnProperty(data.name)) {
        const value = apiName[data.name];
        console.log(`La valeur correspondante est : ${value}`);
    } else {
        console.log("Aucune correspondance trouvée.");
    }

    return (
        <Alert status='warning' borderRadius={'30px'} mb={'20px'}>
            <AlertIcon />
            {value} API is currently experiencing an issue. Please check back later.
        </Alert>
    );
}
