import React, { useEffect, useState } from "react";
import { ethers } from 'ethers';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Flex,
  SimpleGrid,
  useColorModeValue,
  Text,
  Link,
  Button,
  Icon,
} from "@chakra-ui/react";

import { Link as ReactRouterLink } from 'react-router-dom'

// Assets and Custom Components
import Banner from "views/admin/default/components/Banner";
import BannerMarket from "views/admin/default/components/BannerMarket";

import banner from "assets/img/auth/banner.png";
import defaultAvatar from "assets/img/avatars/defaultAvatar.jpg";

import MiniStatistics from "components/card/MiniStatistics";
import { PacmanLoader } from "react-spinners";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import { FaEthereum } from "react-icons/fa";
import { HSeparator } from "components/separator/Separator";

function sortChainDataByScore(data) {
  return data.sort((a, b) => {
    // Obtenir les scores ou définir -Infinity pour les valeurs non définies ou "N/A"
    const scoreA = (a.profilData?.pts.length > 0 && a.profilData.pts[0].nb !== "N/A") ? parseFloat(a.profilData.pts[0].nb) : -Infinity;
    const scoreB = (b.profilData?.pts.length > 0 && b.profilData.pts[0].nb !== "N/A") ? parseFloat(b.profilData.pts[0].nb) : -Infinity;

    // Trier par score de manière décroissante
    return scoreB - scoreA;
  });
}

export default function UserReports() {
  const [chains, setChains] = useState([]);
  const [addressWallet, setAddressWallet] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Chakra Color Mode
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const location = useLocation();

  // Séparer le chemin par "/"
  const pathSegments = location.pathname.split('/');

  // Trouver l'index du segment "dashboard"
  const dashboardIndex = pathSegments.indexOf('dashboard');

  // Obtenir le segment suivant après "dashboard"
  let address = dashboardIndex !== -1 && pathSegments[dashboardIndex + 1];

  const brandColor = useColorModeValue("brand.500", "white");

  // const tmp = useAddress();
  // if (tmp) {
  //   address = tmp;
  // }

  // // Function to fetch data
  // const connectionStatus = useConnectionStatus();

  // if (connectionStatus === "unknown") return <p> Loading... </p>;
  // if (connectionStatus === "connecting")
  //   return <p> Connecting... </p>;
  // if (connectionStatus === "connected")
  //   return <p> You are connected </p>;
  // if (connectionStatus === "disconnected")
  //   return <p> You are not connected to a wallet </p>;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!address || !ethers.utils.isAddress(address)) {
        return;
      }
      try {
        const response = await fetch(`https://drop-tracker-sage.vercel.app/api/v1/${address}`, {
          cache: 'no-store' // Ignore le cache
        })
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        data.blockchainAirdropData = sortChainDataByScore(data.blockchainAirdropData);
        data.dexAirdropData = sortChainDataByScore(data.dexAirdropData);
        setChains(data); // Assuming the API returns an array of chains
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [address]);

  const handleFilter = (filter) => {
    setFilter(filter);
  }

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  if (loading) return (
    <Flex
      align="center"
      justify="center"
      h="100vh" // Utiliser 100% de la hauteur de la fenêtre
      w="100%" // Utiliser 100% de la largeur de la fenêtre
      bg={boxBg} // Utiliser la couleur de fond pour un meilleur contraste
    >
      <PacmanLoader number="2" />
    </Flex>
  );

  if (error) return <Text color="red.500">Error: {error}</Text>;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} mb="8" spacing="15px">
        <Banner
          banner={banner}
          avatar={chains.ens.avatar ? chains.ens.avatar : defaultAvatar}
          name={chains.ens.ensDomain ? chains.ens.ensDomain : "no ens domain"}
          job={chains.walletAddress ? chains.walletAddress : ""}
          posts='17'
          followers='9.7k'
          following='274'
        />
        <BannerMarket />
      </SimpleGrid>
      <Flex
        mt='45px'
        justifyContent='space-between'
        direction={{ base: "column", md: "row" }}
        align={{ base: "start", md: "center" }}>
        <Text color={textColor} fontSize='3xl' ms='24px' fontWeight='700'>
          Wallet data 🔎
        </Text>
        <Flex
          align='center'
          me='20px'
          ms={{ base: "24px", md: "0px" }}
          mt={{ base: "20px", md: "0px" }}>
          <Link
            color={textColorBrand}
            _hover={{ color: "red.300", cursor: "pointer" }}
            fontWeight='500'
            me={{ base: "34px", md: "44px" }}
            name='all'
            onClick={() => handleFilter('')}
          >
            All
          </Link>
          <Link
            _hover={{ color: "red.300", cursor: "pointer" }}
            color={textColorBrand}
            fontWeight='500'
            me={{ base: "34px", md: "44px" }}
            to='#music'
            onClick={() => handleFilter('Blockchain')}
          >
            Blockchain
          </Link>
          <Link
            _hover={{ color: "red.300", cursor: "pointer" }}
            color={textColorBrand}
            fontWeight='500'
            me={{ base: "34px", md: "44px" }}
            to='#music'
            onClick={() => handleFilter('Bridge')}
          >
            Bridge
          </Link>
          <Link
            _hover={{ color: "red.300", cursor: "pointer" }}
            color={textColorBrand}
            fontWeight='500'
            me={{ base: "34px", md: "44px" }}
            to='#collectibles'
            onClick={() => handleFilter('Dex')}
          >
            Dex
          </Link>
        </Flex>
      </Flex>

      {(filter === 'Blockchain' || filter === '') && (
        <>
          <HSeparator mt='20px' mb='20px' />
          <Text fontSize='xl' color={textColor} fontWeight='bold' mb={"10px"} mt={"30px"} ms='24px'>
            Blockchain
          </Text>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 1 }} spacing="15px" mt={"10px"}>
            {chains.blockchainAirdropData && chains.blockchainAirdropData.length > 0 ? (
              chains.blockchainAirdropData.map((chain, index) => (
                <Box
                  key={index}
                  p="20px"
                  bg={boxBg}
                  borderRadius="15px"
                  boxShadow={cardShadow}
                >
                  <Flex
                    align="center"
                    gap={2}
                    wrap={{ base: "wrap", md: "wrap", lg: "wrap", xl: "nowrap" }}
                    direction={{ base: "column", md: "row" }}
                  >
                    <Avatar size="sm" src={chain.logo} mr={{ base: "0", md: "10px" }} mb={{ base: "10px", md: "0" }} />
                    <Text
                      fontSize="lg"
                      fontWeight="bold"
                      minWidth={{ base: "100%", md: "80px" }}
                      mr={{ base: "0", md: "20px" }}
                      mb={{ base: "10px", md: "0" }}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {chain.chain}
                    </Text>
                    {chain.profilData ? (<MiniStatistics
                      growth="+0%"
                      name={
                        chain.chain === "Scroll"
                          ? 'Points earned (Marks)'
                          : chain.chain === "LINEA"
                            ? 'Points earned (LXP-L)'
                            : "Points earned"
                      }
                      value={chain.profilData.pts[0] ? chain.profilData.pts[0].nb : 'N/A'}
                      mb={{ base: "10px", md: "0" }}
                    />) : (<MiniStatistics
                      growth="+0%"
                      name={"Points earned"}
                      value={'No points'}
                      mb={{ base: "10px", md: "0" }}
                    />)}

                    <MiniStatistics
                      growth="+0%"
                      name="Interactions"
                      value={chain.nbTransactions}
                      p="10"
                      mb={{ base: "10px", md: "0" }}
                    />
                    <MiniStatistics
                      growth="+0%"
                      name="Volume total (eth)"
                      value={Number(chain.totalVolumeEth).toFixed(4)}
                      p="10"
                      mb={{ base: "10px", md: "0" }}
                      icon={
                        <Icon w='18px' h='18px' ml='4px' as={FaEthereum} color={brandColor} />
                      }
                    />
                    <MiniStatistics
                      growth="+0%"
                      name="Wallet age"
                      value={`${chain.daysSinceFirstTx} jours`}
                      p="10"
                      mb={{ base: "10px", md: "0" }}
                    />
                    <ReactRouterLink to={`/dashboard/${address}/${chain.chain}`}>
                      <Button ml="5" p="5" mb={{ base: "10px", md: "0" }} rightIcon={<ArrowForwardIcon />} colorScheme='blue' variant='link'>
                        More
                      </Button>
                    </ReactRouterLink>
                  </Flex>
                </Box>
              ))
            ) : (
              <Text>Aucune donnée disponible</Text>
            )}
          </SimpleGrid>
        </>
      )}
      {(filter === 'Dex' || filter === '') && (
        <>
          <HSeparator mt='40px' mb='20px' />
          <Text fontSize='xl' color={textColor} fontWeight='bold' mb={"10px"} mt={"30px"} ms='24px'>
            DEX
          </Text>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 1 }} spacing="15px" mt={"10px"}>
            {chains.dexAirdropData.map((dex, index) => (
              <Box
                key={index}
                p="20px"
                bg={boxBg}
                borderRadius="15px"
                boxShadow={cardShadow}
              >
                <Flex
                  align="center"
                  gap={2}
                  wrap={{ base: "wrap", md: "wrap", lg: "wrap", xl: "nowrap" }}
                  direction={{ base: "column", md: "row" }}
                >
                  <Avatar size="sm" src={dex.logo} mr={{ base: "0", md: "10px" }} mb={{ base: "10px", md: "0" }} />
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    minWidth={{ base: "100%", md: "90px" }}
                    mr={{ base: "0", md: "20px" }}
                    mb={{ base: "10px", md: "0" }}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {dex.name}
                  </Text>
                  {dex.profilData.pts.length > 0 &&
                    <MiniStatistics
                      growth="+0%"
                      name="Points earned"
                      value={parseFloat(dex.profilData.pts[0].nb).toFixed(2)}
                      mb={{ base: "10px", md: "0" }}
                    />}
                  {dex.profilData.pts.length <= 0 &&
                    <MiniStatistics
                      growth="+0%"
                      name="Points earned"
                      value={"No points"}
                      mb={{ base: "10px", md: "0" }}
                    />}
                  <MiniStatistics
                    growth="+0%"
                    name="Interactions"
                    value={dex.nbTransactions}
                    p="10"
                    mb={{ base: "10px", md: "0" }}
                  />
                  {!dex.profilData.pts &&
                    <MiniStatistics
                      growth="+0%"
                      name="Network used"
                      value={dex.network.length}
                      p="10"
                      mb={{ base: "10px", md: "0" }}
                    />}
                  <MiniStatistics
                    growth="+0%"
                    name="Volume total (eth)"
                    value={dex.totalVolumeEth.toFixed(2)}
                    p="10"
                    mb={{ base: "10px", md: "0" }}
                    icon={
                      <Icon w='18px' h='18px' as={FaEthereum} color={brandColor} />
                    }
                  />
                  <MiniStatistics
                    growth="+0%"
                    name="Wallet age"
                    value={`${dex.daysSinceFirstTx} jours`}
                    p="10"
                    mb={{ base: "10px", md: "0" }}
                  />
                  <ReactRouterLink to={`/dashboard/${address}/${dex.name}`}>
                    <Button p="10" mb={{ base: "10px", md: "0" }} rightIcon={<ArrowForwardIcon />} colorScheme='blue' variant='link'>
                      More
                    </Button>
                  </ReactRouterLink>
                </Flex>
              </Box>
            ))}
          </SimpleGrid>
        </>)}

      {(filter === 'Bridge' || filter === '') && (
        <>
          <HSeparator mt='40px' mb='20px' />
          <Text fontSize='xl' color={textColor} fontWeight='bold' mb={"10px"} mt={"30px"} ms='24px'>
            Bridge
          </Text>
          <SimpleGrid columns={{ sm: 1, md: 2, xl: 1 }} spacing="15px" mt={"10px"}>
            {chains.bridgeData.map((bridge, index) => (
              <Box
                key={index}
                p="20px"
                bg={boxBg}
                borderRadius="15px"
                boxShadow={cardShadow}
              >
                <Flex
                  align="center"
                  gap={2}
                  wrap={{ base: "wrap", md: "wrap", lg: "wrap", xl: "nowrap" }}
                  direction={{ base: "column", md: "row" }}
                >
                  <Avatar size="sm" src={bridge.logo} mr={{ base: "0", md: "10px" }} mb={{ base: "10px", md: "0" }} />
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    minWidth={{ base: "100%", md: "90px" }}
                    mr={{ base: "0", md: "20px" }}
                    mb={{ base: "10px", md: "0" }}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {bridge.name}
                  </Text>
                  {bridge.profilData.pts.length > 0 &&
                    <MiniStatistics
                      growth="+0%"
                      name="Points earned"
                      value={parseFloat(bridge.profilData.pts[0].nb).toFixed(2)}
                      mb={{ base: "10px", md: "0" }}
                    />}
                  {bridge.profilData.pts.length <= 0 &&
                    <MiniStatistics
                      growth="+0%"
                      name="Points earned"
                      value={"No points"}
                      mb={{ base: "10px", md: "0" }}
                    />}
                  <MiniStatistics
                    growth="+0%"
                    name="Interactions"
                    value={bridge.nbTransactions}
                    p="10"
                    mb={{ base: "10px", md: "0" }}
                  />
                  {/* <MiniStatistics
                    growth="+0%"
                    name="Network used"
                    value={bridge.nbChainUsed}
                    p="10"
                    mb={{ base: "10px", md: "0" }}
                  /> */}
                  <MiniStatistics
                    growth="+0%"
                    name="Volume total (eth)"
                    value={bridge.totalVolumeEth}
                    p="10"
                    mb={{ base: "10px", md: "0" }}
                    icon={
                      <Icon w='18px' h='18px' ml='4px' as={FaEthereum} color={brandColor} />
                    }
                  />
                  <MiniStatistics
                    growth="+0%"
                    name="Wallet age"
                    value={`${bridge.daysSinceFirstTransaction} jours`}
                    p="10"
                    mb={{ base: "10px", md: "0" }}
                  />
                  <ReactRouterLink to={`/dashboard/${address}/${bridge.name}`}>
                    <Button p="10" mb={{ base: "10px", md: "0" }} rightIcon={<ArrowForwardIcon />} colorScheme='blue' variant='link'>
                      More
                    </Button>
                  </ReactRouterLink>
                </Flex>
              </Box>
            ))}
          </SimpleGrid>
        </>)}
    </Box>
  );
}
