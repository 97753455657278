import { Avatar, Box, Flex, Text, Spacer, Link, Image } from "@chakra-ui/react";

import Card from "components/card/Card";
import { AirdropList } from "../../../../airdropSocialData.js";

export default function AirdropSocialNetwork({ data }) {

    const socialIcons = {
        twitter: "https://upload.wikimedia.org/wikipedia/commons/c/ce/X_logo_2023.svg",
        discord: "https://logo-marque.com/wp-content/uploads/2020/12/Discord-Logo.png",
        website: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Globe_icon.svg",
        telegram: "https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg",
        warpcast: "https://play-lh.googleusercontent.com/cRcdfJ01plmO9AhusWRZ1uyrjcYbbMMiyqTakPEHatoNVEzxtFt-78GJ7IZX-1cd2Vz2" // Ajoute d'autres icônes ici
    };

    const projectInfo = AirdropList[data.name.toLowerCase()];

    if (!projectInfo) {
        return <p>Projet non trouvé.</p>;
    }

    return (
        <Card py='15px' mb='20px'>
            <Flex
                my='auto'
                h='100%'
                align={{ base: "center", xl: "start" }}
                justify={{ base: "start", xl: "start" }}>
                <Box p='4'>
                    <Flex>
                        <Avatar size="sm" src={data.logo} />
                        <Text fontSize="xl" fontWeight="bold" ml={'10px'} style={{ textTransform: 'capitalize' }}>
                            {data.name}
                        </Text>
                    </Flex>
                </Box>
                <Spacer />
                <Box p='4'>
                    <Flex>
                        {Object.entries(projectInfo).map(([key, value]) => (
                            key !== "website" ? (
                                <Link key={key} _hover={{ opacity: "0.6" }} href={value} ml={key !== 'twitter' ? '20px' : '0'}>
                                    <Image
                                        src={socialIcons[key] || socialIcons['website']}  // Utilise une icône par défaut si aucune correspondance
                                        alt={`${key} logo`}
                                        w={key === 'discord' ? "35px" : "23px"}  // Change la taille en fonction du réseau social
                                        color={"white"}
                                    />
                                </Link>
                            ) : null
                        ))}
                    </Flex>
                </Box>
            </Flex>
        </Card>
    )
}