import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from './layouts/admin';
import StrategiesLayout from './layouts/strategies';
import React from 'react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';

import {
  ThirdwebProvider,
  metamaskWallet,
  coinbaseWallet,
  zerionWallet,
  rabbyWallet,
  rainbowWallet,
  walletConnect,
} from "@thirdweb-dev/react";
import Overview from 'views/admin/profile';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={theme}>
      {/* <React.StrictMode> */}
        <ThirdwebProvider
          supportedWallets={[
            rabbyWallet({
              recommended: true,
            }),
            metamaskWallet(),
            coinbaseWallet(),
            walletConnect(),
            zerionWallet(),
            rainbowWallet(),
          ]}
          clientId="ce72158151c0577b39b08a6c4b6ca2ed"
        >
          <ThemeEditorProvider>
            <Routes>
              <Route
                path="dashboard/*"
                element={
                  <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
                }
              />
              {/* <Route path="dashboard/*" element={<AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />}>
                {/* Routes pour le dashboard */}
                {/* <Route path=":walletAddress/:protocol" element={<Overview />} /> */}
              {/* </Route> */} 
              <Route
                path="strategies/*"
                element={
                  <StrategiesLayout theme={currentTheme} setTheme={setCurrentTheme} />
                }
              />
               <Route path="dashboard/:walletAddress/:protocol" element={<Overview />} />
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          </ThemeEditorProvider>
        </ThirdwebProvider>
      {/* </React.StrictMode> */}
    </ChakraProvider>
  );
}
