import { Box, Text, useColorModeValue } from "@chakra-ui/react";

import React from "react";

export default function StrategiesView() {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
       <Text pt={"30px"} color={textColor} fontSize='3xl' ms='24px' fontWeight='700'>
          Coming soon... 😏
        </Text>
    </Box>
  );
}
