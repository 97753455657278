const AirdropList = {
    "contango": {
        "twitter": "https://x.com/Contango_xyz",
        "discord": "https://discord.com/invite/x3dync2edA",
        "website": "https://app.contango.xyz/",
    },
    "syncswap": {
        "twitter": "https://x.com/syncswap",
        "discord": "https://discord.com/invite/syncswap",
        "website": "https://syncswap.xyz/",
    },
    "ambient": {
        "twitter": "https://x.com/ambient_finance",
        "discord": "https://t.co/5v53IrtLqL",
        "website": "https://ambient.finance/",
    },
    "linea": {
        "twitter": "https://x.com/LineaBuild",
        "website": "https://linea.build/",
        "discord": "https://discord.gg/linea",
    },
    "scroll": {
        "twitter": "https://x.com/Scroll_ZKP",
        "discord": "http://discord.gg/scroll",
        "website": "https://scroll.io/",
    },
    "base": {
        "twitter": "https://x.com/base",
        "discord": "https://discord.com/invite/buildonbase",
        "website": "https://www.base.org/",
    },
    "fuel": {
        "twitter": "https://x.com/fuel_network",
        "warpcast": "https://warpcast.com/fuel-network",
        "discord": "https://discord.com/invite/fuelnetwork",
        "website": "https://fuel.network/",
        "telegram": "https://t.me/fuelcommunity",
        
    },
    "zora": {
        "twitter": "https://x.com/zora",
        "telegram": "https://t.me/contangoHQ",
        "discord": "https://discord.gg/contango",
        "website": "https://zora.co/",
    }
}

export { AirdropList };