import React, { useState } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers"; 

export function SearchBar(props) {
  const { variant, background, borderRadius, placeholder, ...rest } = props;
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(""); 
  const navigate = useNavigate();

  const handleSearch = (event) => {
    event.preventDefault(); // Empêche le rechargement de la page
    setError(""); // Réinitialise les erreurs

    if (searchQuery.trim()) {
      // Vérifier si l'adresse Ethereum est valide
      if (ethers.utils.isAddress(searchQuery)) {
        navigate(`/dashboard/${searchQuery}`);
      } else {
        setError("Adresse Ethereum invalide"); // Affiche un message d'erreur
      }
    }
  };

  return (
    <form onSubmit={handleSearch}>
      <InputGroup w={{ base: "100%", md: "200px" }} {...rest}>
        <InputLeftElement>
          <IconButton
            type="submit" // Déclenche la soumission du formulaire
            bg='inherit'
            borderRadius='inherit'
            _hover='none'
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w='15px' h='15px' />}
          />
        </InputLeftElement>
        <Input
          variant='search'
          fontSize='sm'
          bg={background ? background : inputBg}
          color={inputText}
          fontWeight='500'
          _placeholder={{ color: "gray.400", fontSize: "14px" }}
          borderRadius={borderRadius ? borderRadius : "30px"}
          placeholder={placeholder ? placeholder : "Wallet address..."}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Met à jour l'état à chaque changement
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch(e); // Soumet le formulaire lorsque 'Enter' est pressé
            }
          }}
        />
      </InputGroup>
    </form>
  );
}
