/* eslint-disable */
import React from "react";
import { NavLink } from "react-router-dom";
import { matchPath, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

import { useAddress } from "@thirdweb-dev/react";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;


  // Fonction pour vérifier si une route est active
  const isActiveRoute = (route) => {
    const location = useLocation();
    const pathname = location.pathname;

    const fullPath = route.layout + route.path;

    if (typeof fullPath !== 'string') {
      console.error("Invalid fullPath", fullPath);
      return false;
    }
    const match = matchPath({
      path: fullPath,
    }, pathname);
    return !!match;
  };

  // Fonction pour obtenir la route actuelle
  const useCurrentRoute = () => {
    const currentRoute = routes.find(route => isActiveRoute(route));
    return currentRoute;
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    const location = useLocation();
    const pathname = location.pathname;

    // Assurez-vous que routePath est une chaîne de caractères
    if (typeof routeName !== 'string') {
      console.error("routePath must be a string");
      return false;
    }
    // Crée un chemin complet basé sur le modèle de route
    const fullPath = routeName; // Dans votre cas, routePath est déjà un chemin complet

    // Utilise matchPath pour comparer le pathname actuel avec le modèle de route
    const match = matchPath({
      path: fullPath,
    }, pathname);

    return !!match;
    // console.log("routeName = ", routeName);
    // console.log("location.pathname", location.pathname);
    // console.log("", location.pathname.includes(routeName));
    // return location.pathname.includes(routeName);
  };

  const getLink = (route, params) => {
    let path = route.layout + route.path;

    // Remplace les paramètres dynamiques par leurs valeurs
    for (const key in params) {
      path = path.replace(`:${key}`, params[key]);
    }

    return path;
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {

    const params = { walletAddress: '0x6D1ADCFD648CE1beC262ee54cEa57CBfCC964361' };// TODO add dynamic params

    const tmp = useAddress();
    if (tmp) {
      params.walletAddress = tmp;
    }
    return routes.map((route, index) => {
      if (
        route.layout === "/dashboard" ||
        route.layout === "/strategies"
      ) {
        return (
          <NavLink key={index} to={getLink(route, params)}>
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    isActiveRoute(route) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        isActiveRoute(route)
                          ? activeIcon
                          : textColor
                      }
                      me='18px'
                      mt='5px'>
                        
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={
                        isActiveRoute(route)
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        isActiveRoute(route)
                          ? "bold"
                          : "normal"
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      isActiveRoute(route)
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    isActiveRoute(route) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      isActiveRoute(route)
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      isActiveRoute(route) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
